import React from 'react';
import {List, Datagrid, TextField, EmailField, EditButton} from 'react-admin';
import {DateTimeField} from '../../fields/dateTime';
import {TagsListField} from '../../fields/tagsList';
import {LessActions} from '../../components/lessActions';

export const ManagersList = props => <List {...props}
  actions={<LessActions />}
  bulkActionButtons={false}
>
  <Datagrid rowClick="edit">
    <TextField source="id" />
    <TextField source="name" cellClassName="App-comment" />
    <EmailField source="email" />
    <TagsListField source="roles" />
    <DateTimeField source="createdAt" />
    <DateTimeField source="updatedAt" />
    <EditButton/>
  </Datagrid>
</List>;