import * as React from "react";
import { Show, SimpleShowLayout, TextField, UrlField, DateField, RichTextField, TopToolbar, ListButton, ArrayField, Datagrid } from 'react-admin';
import Icon from "@material-ui/icons/Close";

const FeedbackCloseActions = ({ basePath }) => <TopToolbar>
  <ListButton
    basePath={basePath}
    color="primary"
    label="Close"
    icon={<Icon />}
  />
</TopToolbar>;



export const FeedbackShow = (props) => (
    <Show {...props} actions={<FeedbackCloseActions />}>
        <SimpleShowLayout>
            <TextField source="user.name" />
            <TextField source="user.phone" />
            <TextField source="link" />
            <TextField source="page" />
            <RichTextField source="text" />
            <ArrayField source="files">
              <Datagrid>
                <UrlField source="url" target="_blank" />
              </Datagrid>
            </ArrayField>
            <DateField label="Publication date" source="createdAt" />
        </SimpleShowLayout>
    </Show>
);
