import * as React from "react";
import { createBrowserHistory } from 'history';
import * as _ from 'lodash';
import { Admin, Resource, Login, ListGuesser } from 'react-admin';
import defaultEnglishMessages from 'ra-language-english';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import { dataProvider } from './dataProvider';
import { AuthProvider } from './authProvider';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import PersonIcon from '@material-ui/icons/Person';
import { ManagersList, ManagerEdit, ManagerCreate } from './resources/managers'
import { UsersList, UserEdit } from './resources/users';
import { FileList, FileEdit, FileCreate } from './resources/file';
import { FeedbackList, FeedbackShow } from './resources/feedback';
import { PaymentsList, PaymentsEdit } from './resources/payments';
import { WithdrawsList, WithdrawsEdit } from './resources/withdraws';


const englishMessages = {};
_.defaultsDeep(englishMessages, defaultEnglishMessages);

const i18nProvider = polyglotI18nProvider(
  (locale) => {
    switch (locale) {
      case "en":
        return englishMessages;
      default:
        return englishMessages;
    }
  },
  "en" // Default locale
);

const LoginPage = (props) => (
  <Login {...props} backgroundImage="" />
);
const authProvider = new AuthProvider();



const history = createBrowserHistory();

const App = () => 
  <Admin 
    history={history}
    i18nProvider={i18nProvider}
    dataProvider={dataProvider}
    authProvider={authProvider}
    loginPage={LoginPage}
  >
    <Resource name="managers" list={ManagersList} edit={ManagerEdit} create={ManagerCreate} icon={SupervisorAccountIcon} />
    <Resource name="users" list={UsersList} edit={UserEdit} icon={PersonIcon} />
    <Resource name="file" list={FileList} edit={FileEdit} create={FileCreate} />
    <Resource name="feedback" list={FeedbackList} show={FeedbackShow} />
    <Resource name="payments" list={PaymentsList} edit={PaymentsEdit} />
    <Resource name="withdraws" list={WithdrawsList} edit={WithdrawsEdit} />
  </Admin>;

export default App;