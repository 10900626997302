/* eslint-disable react/prop-types */
import {
  List,
  Datagrid,
  TextField,
  SearchInput,
  Filter,
  DateInput,
  EditButton,
  BooleanField,
} from 'react-admin';
import {DateTimeField} from '../../fields/dateTime';
import React from "react";
import '../../App.css';

const UsersFilter = (props) => <Filter {...props}>
  <SearchInput source="q" alwaysOn />
  <DateInput source="createdAt_from" />
  <DateInput source="createdAt_to" />
  <DateInput source="lastActive_from" />
  <DateInput source="lastActive_to" />
  <DateInput source="lastVisit_from" />
  <DateInput source="lastVisit_to" />
</Filter>;

export const UsersList = props => <List {...props}
  // actions={<Actions />}
  exporter={false}
  bulkActionButtons={false}
  filters={<UsersFilter />}>
  <Datagrid  rowClick="edit">
    <TextField source="phone" />
    <TextField source="payedFor" />
    <TextField source="balance" />
    <BooleanField source="unlimited" />
    <DateTimeField source="createdAt" />
    <DateTimeField source="updatedAt" />
    <DateTimeField source="lastActive" />
    <EditButton />
  </Datagrid>
</List>;
