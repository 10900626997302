/* eslint-disable react/prop-types */
import {
  List,
  Datagrid,
  TextField,
  SearchInput,
  Filter,
  DateInput,
  EditButton,
  ShowButton,
  BooleanField,
  UrlField,
} from 'react-admin';
import {DateTimeField} from '../../fields/dateTime';
import React from "react";
import '../../App.css';

const FeedbackFilter = (props) => <Filter {...props}>
  <SearchInput source="phone" placeholder="Phone" alwaysOn />
  <SearchInput source="name" placeholder="Name" alwaysOn />
  <SearchInput source="text" placeholder="Text" alwaysOn />
</Filter>;

export const FeedbackList = props => <List {...props}
  // actions={<Actions />}
  exporter={false}
  bulkActionButtons={false}
  filters={<FeedbackFilter />}>
  <Datagrid  rowClick="edit">
    <UrlField source="link" />
    <TextField source="page" />
    <TextField source="text" />
    <TextField source="user.name" />
    <TextField source="user.phone" />
    <DateTimeField source="createdAt" />
    <ShowButton />
  </Datagrid>
</List>;
