import React, { useState } from "react";
import {
  Edit,
  TabbedForm,
  FormTab,
  TextInput,
  TopToolbar,
  ListButton,
  BooleanInput,
  TextField,
  Datagrid,
  useEditController,
  Pagination,
  ListContextProvider,  
  useQuery,
} from "react-admin";
import { keyBy } from 'lodash';
import Icon from "@material-ui/icons/Close";
import { DateTimeField } from "../../fields/dateTime";
import { SaveDeleteToolbar } from "../../components/saveDeleteToolbar";

const UserCloseActions = ({ basePath }) => <TopToolbar>
  <ListButton
    basePath={basePath}
    color="primary"
    label="Close"
    icon={<Icon />}
  />
</TopToolbar>;

const OrdersList = ({ userId }) => {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const { data, total, loading, error } = useQuery({
    type: 'GET_LIST',
    resource: 'orders',
    payload: {
      pagination: { page, perPage },
      sort: { field: 'updatedAt', order: 'DESC' },
      filter: { userId },
    }
  });

  if (loading) {
      return <div>Loading...</div>
  }
  if (error) {
      return <p>ERROR: {error}</p>
  }
  return <ListContextProvider value={{
    data: keyBy(data, 'id'),
    ids: data.map(({ id }) => id),
    total,
    page,
    perPage,
    setPage,
    setPerPage,
    currentSort: { field: 'updatedAt', order: 'DESC' },
  }}>
    <Datagrid>
      <TextField source="type" />
      <TextField source="orderId" />
      <TextField source="status" />
      <TextField source="amount" />
      <TextField source="balance" />
      <TextField source="currency" />
      <TextField source="payedFor" />
      <DateTimeField source="createdAt" />
      <DateTimeField source="updatedAt" />
    </Datagrid>
    <Pagination />
  </ListContextProvider>;
}

export const UserEdit = (props) => {
  const controllerProps = useEditController(props);
  const { record } = controllerProps;
  return <Edit {...props} undoable={false} actions={<UserCloseActions />}>
    <TabbedForm autoComplete="off" toolbar={<SaveDeleteToolbar />}>
      <FormTab label="info" key="info">
        <TextInput source="phone"/>
        <TextInput source="name"/>
        <TextInput source="payedFor" pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}" placeholder="ГГГГ-ММ-ДД"/>
        <BooleanInput source="unlimited" />
        <DateTimeField source="createdAt"/>
        <DateTimeField source="updatedAt"/>
        <DateTimeField source="lastActive"/>
      </FormTab>
      <FormTab label="orders" key="orders">         
        <OrdersList userId={record?.id}/> 
      </FormTab>
    </TabbedForm>
  </Edit>;
};
