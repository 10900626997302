import React from 'react';
import { DeleteButton, SaveButton, Toolbar } from 'react-admin';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  }
}));

export const SaveDeleteToolbar = (props) => {
  const classes = useStyles();
  return (
    <Toolbar {...props}>
      <SaveButton label="save" className={classes.button}/>
      <DeleteButton
        label="delete"
        size="medium"
      />
    </Toolbar>
  );
};
