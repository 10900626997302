import React, { useState } from "react";
import {
  Create,
  Edit,
  TabbedForm,
  FormTab,
  SimpleForm,
  TextInput,
  TopToolbar,
  ListButton,
  BooleanInput,
  FileInput,
  FileField,
  TextField,
  Datagrid,
  useEditController,
  Pagination,
  ListContextProvider,  
  useQuery,
  DeleteButton,
  SaveButton,
  Toolbar,
  showNotification,
} from 'react-admin';
import { connect } from 'react-redux';
import { keyBy } from 'lodash';
import Icon from "@material-ui/icons/Close";
import { DateTimeField } from "../../fields/dateTime";
import { SaveDeleteToolbar } from "../../components/saveDeleteToolbar";
import {client} from "../../dataProvider";

const transform = async (input) => {
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  if (input?.file?.rawFile) {
    const form = new FormData();
    form.append('file', input.file.rawFile);
    const { data } = await client.post("file/upload", form, config);
    input.path = data.file;
  }
  return input;
};


const UserCloseActions = ({ basePath }) => <TopToolbar>
  <ListButton
    basePath={basePath}
    color="primary"
    label="Close"
    icon={<Icon />}
  />
</TopToolbar>;

export const FormToolbar = (props) => {
  return (
    <Toolbar {...props}>
      <SaveButton label="save" />
      <DeleteButton label="delete" />
    </Toolbar>
  );
};

export const FileEdit = connect(undefined, { showNotification })((props) => {
  return <Edit {...props} undoable={false} actions={<UserCloseActions />}>
    <SimpleForm autoComplete="off" toolbar={<FormToolbar />}>
        <TextField source="id"/>
        <TextField source="department"/>
        <TextField source="type"/>
        <TextField source="from"/>
        <TextField source="to"/>
        <TextField source="path"/>
        <DateTimeField source="createdAt"/>
        <DateTimeField source="updatedAt"/>
    </SimpleForm>
  </Edit>;
});

export const FileCreate = connect(undefined, { showNotification })(props => <Create {...props}
  transform={transform}
  onSuccess={() => props.showNotification('File uploaded')}
  onFailure={() => props.showNotification('Error')}
>
  <SimpleForm>
    <FileInput source="file">
      <FileField source="src" title="file" />
    </FileInput>
  </SimpleForm>
</Create>);