import * as React from "react";
import { Edit, SimpleForm, TextField, UrlField, DateField, SelectInput, Toolbar, TopToolbar, ListButton, SaveButton, ArrayField, Datagrid } from 'react-admin';
import Icon from "@material-ui/icons/Close";

const PaymentsCloseActions = ({ basePath }) => <TopToolbar>
  <ListButton
    basePath={basePath}
    color="primary"
    label="Close"
    icon={<Icon />}
  />
</TopToolbar>;

const PaymentsEditToolbar = props => (
  <Toolbar {...props} >
      <SaveButton disabled={props.invalid}/>
  </Toolbar>
); 

const WithProps = ({children,...props}) => children(props);

export const PaymentsEdit = (props) => (
    <Edit {...props} actions={<PaymentsCloseActions />}>
      <WithProps {...props}>{({...props})=>
        <SimpleForm toolbar={<PaymentsEditToolbar />} {...props}>
            <TextField source="user.phone" />
            <TextField source="plan" />
            <UrlField source="url" target="_blank" />
            <TextField source="amount" />
            <TextField source="partnerAmount" />
            <SelectInput source="status" choices={[
              { id: 'confirmed', name: 'confirmed' },
              { id: 'pending', name: 'pending' },
              { id: 'suspended', name: 'suspended' },
              { id: 'rejected', name: 'rejected' },
            ]} disabled={props.record.disabled}
            />
            <ArrayField source="files">
              <Datagrid>
                <UrlField source="url" target="_blank" />
              </Datagrid>
            </ArrayField>
            <DateField label="Payment date" source="createdAt" />
            <DateField source="start" />
            <DateField source="payedFor" />
        </SimpleForm>
      }</WithProps>  
    </Edit>
);
