import React from 'react';
import { List, Datagrid, TextField, EmailField, EditButton, Filter, ReferenceInput, SelectInput,
  TextInput, FilterList, FilterListItem, AutocompleteInput, ReferenceArrayInput } from 'react-admin';
import { DateTimeField } from '../../fields/dateTime';
import { LessActions } from '../../components/lessActions';

const places = [
  'Amazon.com',
  'Amazon.de',
  'Amazon.ca',
  'Amazon.com.br',
  'Amazon.com.mx',
  'Amazon.co.uk',
];

const BrandFilter = (props) => {
  return (
    <Filter {...props}>
      <ReferenceArrayInput source="department" alwaysOn={true} >
        <SelectInput choices={
            places.map(place => ({ id: place, name: place }))
          }
        />
      </ReferenceArrayInput>
    </Filter>
  )
}




export const FileList = props => <List {...props}
  bulkActionButtons={false}

  filters={<BrandFilter/>}
>
  <Datagrid rowClick="edit">
    <TextField source="department" />
    <TextField source="type" />
    <TextField source="from" />
    <TextField source="to" />
    <TextField source="count" />
    <DateTimeField source="createdAt" />
    <TextField source="path" cellClassName="App-comment" />
    <EditButton />
  </Datagrid>
</List>;