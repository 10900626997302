import * as React from "react";
import { Edit, SimpleForm, TextField, UrlField, DateField, SelectInput, Toolbar, TopToolbar, ListButton, SaveButton, ArrayField, Datagrid, BooleanField } from 'react-admin';
import Icon from "@material-ui/icons/Close";

const WithdrawsCloseActions = ({ basePath }) => <TopToolbar>
  <ListButton
    basePath={basePath}
    color="primary"
    label="Close"
    icon={<Icon />}
  />
</TopToolbar>;

const WithdrawsEditToolbar = props => (
  <Toolbar {...props} >
      <SaveButton disabled={props.invalid}/>
  </Toolbar>
); 

const WithProps = ({children,...props}) => children(props);

export const WithdrawsEdit = (props) => (
    <Edit {...props} actions={<WithdrawsCloseActions />}>
      <WithProps {...props}>{({...props})=>
        <SimpleForm toolbar={<WithdrawsEditToolbar />} {...props}>
            <TextField source="user.name" />
            <TextField source="user.phone" />
            <TextField source="email" />
            <TextField source="amount" />
            <BooleanField source="partner" />
            <SelectInput source="status" choices={[
              { id: 'confirmed', name: 'confirmed' },
              { id: 'pending', name: 'pending' },
              { id: 'suspended', name: 'suspended' },
              { id: 'rejected', name: 'rejected' },
            ]} disabled={props.record.disabled}
            />
            <DateField label="Payment request date" source="createdAt" />
        </SimpleForm>
      }</WithProps>  
    </Edit>
);
