import { dataProvider } from './dataProvider';

export const AUTH_KEY = '=U5kKfscTB,sB~Pd';
export const ACCESS_TOKEN = 'a;eCzax.=e%DK4wB';
export const REFRESH_TOKEN = '!U9hNc?3tC885k36';

export class AuthProvider{
    async login(params) {
      try {
        const { data, accessToken, refreshToken } = await dataProvider('LOGIN', 'managers', {
          data: {
            email: params.username,
            password: params.password
          }
        })
        data.fullName = data.name;
        sessionStorage.setItem(AUTH_KEY, JSON.stringify(data));
        sessionStorage.setItem(ACCESS_TOKEN, accessToken);
        sessionStorage.setItem(REFRESH_TOKEN, refreshToken);
        return data;
      } catch(ex) {
        console.log('login error ', ex);
        this.logout();
      }
    }

    checkError(error) {
      console.log('checkError', error);
      return console.log('error', error) || Promise.resolve(error)
    }
    checkAuth(params) {
      console.log('checkAuth', params)
      return this.getUser() ? Promise.resolve() : Promise.reject();
    }

    logout() {
      console.log('logout')
      sessionStorage.removeItem(AUTH_KEY);
      sessionStorage.removeItem(ACCESS_TOKEN);
      sessionStorage.removeItem(REFRESH_TOKEN);
      return Promise.resolve();
    }

    getIdentity() {
      const user = this.getUser();
      user.fullName = user.email; // for UserMenu default field
      return user ? Promise.resolve(user) : Promise.reject();
    }

    getPermissions(params) {
      console.log('getPermissions', params);
      const user = this.getUser();
      return user ? Promise.resolve(user) : Promise.reject();
    }

    getUser() {
      return JSON.parse(sessionStorage.getItem(AUTH_KEY));
    }

    getUserId() {
      return this.getUser()?.id;
    }
}
