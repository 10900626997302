/* eslint-disable react/prop-types */
import {
  List,
  Datagrid,
  TextField,
  SearchInput,
  Filter,
  DateInput,
  EditButton,
  ShowButton,
  BooleanField,
  UrlField,
  DateField,
  SelectInput,
} from 'react-admin';
import {DateTimeField} from '../../fields/dateTime';
import React from "react";
import '../../App.css';

const WithdrawsFilter = (props) => <Filter {...props}>
  <SearchInput source="phone" placeholder="Phone" alwaysOn />
  <SearchInput source="name" placeholder="Name" alwaysOn />
  <DateInput source="createdAt_from" />
  <DateInput source="createdAt_to" />
  <SelectInput source="plan" choices={[
    { id: 'trial', name: 'trial' },
    { id: 'free', name: 'free' },
    { id: 'month', name: 'month' },
    { id: 'year', name: 'year' },
  ]}/>
  <SelectInput source="status" choices={[
    { id: 'confirmed', name: 'confirmed' },
    { id: 'pending', name: 'pending' },
    { id: 'suspended', name: 'suspended' },
    { id: 'rejected', name: 'rejected' },
  ]}/>
</Filter>;

export const WithdrawsList = props => <List {...props}
  // actions={<Actions />}
  exporter={false}
  bulkActionButtons={false}
  filters={<WithdrawsFilter />}>
  <Datagrid  rowClick="edit">
    <TextField source="user.name" />
    <TextField source="user.phone" />
    <TextField source="email" />
    <TextField source="amount" />
    <BooleanField source="partner" />
    <TextField source="status" />
    <DateTimeField source="createdAt" />
    <DateTimeField source="updatedAt" />
    <EditButton />
  </Datagrid>
</List>;
