import moment from 'moment-timezone';
import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import Typography from '@material-ui/core/Typography';

const DateTimeFieldClass = ({ className, source, record = {}, ...rest }) => {
  const value = get(record, source);
  if (!value) {
    return null;
  }
  return (
    <Typography
      component="span"
      body1="body1"
      className={className}
      {...rest}
    >
      {moment(value).format('DD.MM.YYYY HH:mm')}
    </Typography>
  );
};

DateTimeFieldClass.propTypes = {
  addLabel: PropTypes.bool,
  basePath: PropTypes.string,
  className: PropTypes.string,
  cellClassName: PropTypes.string,
  headerClassName: PropTypes.string,
  label: PropTypes.string,
  record: PropTypes.object,
  sortBy: PropTypes.string,
  source: PropTypes.string.isRequired,
};

DateTimeFieldClass.displayName = 'DateTimeField';

export const DateTimeField = DateTimeFieldClass;

DateTimeField.defaultProps = { addLabel: true };
