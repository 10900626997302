import React from "react";
import {
  Create,
  Edit,
  SimpleForm,
  TextInput,
  SelectArrayInput,
  TopToolbar,
  ListButton,
  required,
  email,
} from "react-admin";
import Icon from "@material-ui/icons/Close";
import { SaveDeleteToolbar } from "../../components/saveDeleteToolbar";

const validate = values => {
  const errors = { };
  if (values.password && values.password.length < 8) {
    errors.password = ['password less 8 symbol'];
  }
  if (values.password !== values.password1) {
    errors.password1 = ['password missmatch'];
  }
  return errors;
};

// eslint-disable-next-line react/prop-types
const ManagerCloseActions = ({ basePath }) => <TopToolbar>
  <ListButton
    basePath={basePath}
    color="primary"
    label="Close"
    icon={<Icon />}
  />
</TopToolbar>;

export const ManagerCreate = props => <Create
  {...props}
  undoable={false}
  actions={<ManagerCloseActions />}
>
  <SimpleForm validate={validate}>
    <TextInput source="name" validate={required()} />
    <TextInput source="email" type="email" validate={[required(), email()]} />
    <SelectArrayInput
      source="roles"
      choices={[
        { id: 'admin', name: 'admin' },
        { id: 'manager', name: 'manager' },
      ]}
      validate={required()}
      rowClick="edit"
    />
    <TextInput
      type="password"
      source="autofilled_password"
      autoComplete="off"
      style={{ display: 'none' }}
    />
    <TextInput type="password" source="password" autoComplete="off" />
    <TextInput type="password" source="password1" autoComplete="off" />
  </SimpleForm>
</Create>;

export const ManagerEdit = props =>  <Edit
  {...props}
  undoable={false}
  actions={<ManagerCloseActions />}
>
  <SimpleForm validate={validate} autoComplete="off" toolbar={<SaveDeleteToolbar/>}>
    <TextInput source="name" validate={required()}/>
    <TextInput source="email" type="email" validate={[required(), email()]}/>
    <SelectArrayInput
      source="roles"
      choices={[
        {id: "admin", name: "admin"},
        {id: "manager", name: "manager"},
      ]}
      validate={required()}
    />
    <TextInput
      type="password"
      source="autofilled_password"
      autoComplete="off"
      style={{visibility: "hidden", height: 0}}
    />
    <TextInput type="password" source="password" autoComplete="off"/>
    <TextInput type="password" source="password1" autoComplete="off"/>
  </SimpleForm>
</Edit>;
